<script setup lang="ts">
const { isInitialized, initializeTheme, partner } = useTheme();
const { setOfficialName, subdomain } = useTexts();

const { initialize } = useAuth();

onMounted(() => {
  initializeTheme().then(() => {
    if (partner.value?.displayName) {
      setOfficialName(partner.value.displayName);
    } else {
      setOfficialName(subdomain);
    }
  });
  initialize();
});

const navigationMenuStore = useNavigationMenuStore();

// const route = useRoute();

// const showWhatsappButton = computed(() => {
//   return route.name === "u-code-bearbeiten" || route.name === "index";
// });

const checkoutStore = useCheckoutStore();
</script>

<template>
  <Transition name="fade">
    <div v-if="isInitialized" class="relative">
      <div
        :class="[
          'w-screen relative flex',
          navigationMenuStore.showMenu
            ? 'overflow-y-hidden h-[100vsh]'
            : 'overflow-auto',
        ]"
      >
        <!-- <a
          v-if="showWhatsappButton"
          href="https://wa.me/+4915735532709"
          class="w-[60px] h-[60px] bg-[#1AD65F] rounded-full shadow-lg shadow-black/40 fixed z-10 bottom-4 right-4 flex items-center justify-center cursor-pointer"
        >
          <icon
            name="logos:whatsapp-icon"
            class="w-[30px] h-[30px] text-white"
          />
        </a> -->
        <AppBar />
        <NavigationMenu class="md:hidden" />
        <Transition name="slide-left">
          <BuyPopupCtaMain v-if="checkoutStore.showPopup" />
        </Transition>
        <NuxtPage />
      </div>
    </div>
  </Transition>
</template>

<style lang="postcss">
html {
  overscroll-behavior: none;
}

body {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  background: rgb(255, 255, 255);
  @apply font-body;
}
</style>
